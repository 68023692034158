.contentEditable {
  height: 300px;
  width: 98%;
  padding: 0 8px;
  border: 1px solid black;
}

.placeholder {
  position: absolute;
  top: 40px;
  padding-left: 8px;
}

.editorWrapper {
  position: relative;
}

.toolbar-wrapper {
  display: flex;
}

.trap-editor-bold {
  color: violet;
}

.trap-editor-italic {
  color: green;

}

.trap-editor-h1 {
  font-size: 48px;
  text-decoration: underline;
  font-weight: bold;
}

.trap-editor-h2 {
  font-size: 40px;
  font-weight: bold;
  color: green;
}

.trap-editor-h3 {
  font-size: 32px;
}

.trap-editor-banner {
  background-color: rgb(255, 255, 0);
  margin: 8px 0;
  padding: 8px;
  border-left: 4px solid rgb(255, 235, 0, 1);
}

.contentEditable {
  height: 300px; /* Set your desired height */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling if not needed */
  padding: 10px;
  border: 1px solid #ddd; /* Optional: a border to make the editor look distinct */
  box-sizing: border-box; /* Ensures padding is included in the height */
  white-space: pre-wrap; /* Ensure text wraps properly */
  word-wrap: break-word; /* Break long words */
}